import { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { handleFormatDateKeyUp, useBooleanInput } from '@pumpkincare/shared';
import {
  Body1,
  Body2,
  ButtonStyles,
  Checkbox,
  ChevronRight,
  LegalBody,
  Select,
  TextArea,
  TextField,
  Tooltip,
  Trash,
  Typography,
} from '@pumpkincare/shared/ui';

import ClaimFormAccordion from '../claim-form-accordion';
import {
  ACCIDENT,
  CONDITION_DATE,
  CONDITION_TYPE,
  CONDITIONS_SERVICES_INITIAL_STATE,
  GENERIC,
  ILLNESS,
  INCIDENTS,
  VISIT_REASON,
  WELLNESS,
} from './claim-conditions-services-shared';
import {
  isInvalidClaimConditionsServices,
  validateClaimConditionsServices,
} from './claim-conditions-services-validations';

import styles from './claim-conditions-services.css';

import plusIcon from './plus.svg';

/*
   if open, show all incidents
   if closed, show 5 incidents or more if 6+ are selected
   move selected incidents to the top
 */
export function formatIncidentsToShow(incidents, selectedState, isShowAllToggle) {
  const { selected, unselected } = incidents.reduce(
    (result, incident) => {
      const key = selectedState[incident.sub_diagnosis.id]
        ? 'selected'
        : 'unselected';

      result[key].push(incident);

      return result;
    },
    { selected: [], unselected: [] }
  );

  const isShowAll = incidents.length <= 5 || isShowAllToggle;
  if (isShowAll) return [...selected, ...unselected];

  const unselectedToAdd = Math.max(0, 5 - selected.length);
  return [...selected, ...unselected.slice(0, unselectedToAdd)];
}

export function transformIncidentsForSubmit(incidentsData, selectedIncidents = {}) {
  return Object.entries(selectedIncidents)
    .map(([key, value]) =>
      value ? incidentsData.find(x => x.sub_diagnosis.id === key) : null
    )
    .filter(incident => incident);
}

function ClaimConditionsServices({
  canEdit,
  conditions,
  incidents,
  isComplete,
  isOpen,
  onEditClick,
  onSubmit,
  pet,
}) {
  const [formData, setFormData] = useState(CONDITIONS_SERVICES_INITIAL_STATE);
  const [errors, setErrors] = useState({});
  const [isShowAllIncidents, toggleShowAllIncidents] = useBooleanInput(false);

  // these values ignore statuses - claims can reject claims on their side if canceled / expired
  const canFileAccidentIllness = !!pet.policies.length;
  const canFileWellness = !!pet.plans.length || !!pet.wellness.length;

  function handleSubmit(e) {
    e.preventDefault();

    const invalid = validateClaimConditionsServices({ data: formData });
    if (isInvalidClaimConditionsServices(invalid)) {
      return setErrors(invalid);
    }

    onSubmit({
      conditions: {
        ...formData,
        incidentHistory: transformIncidentsForSubmit(incidents, formData[INCIDENTS]),
      },
    });
  }

  function handleNewConditionCheckboxChange(e) {
    const { name } = e.target;

    const isNested = [ACCIDENT, ILLNESS].some(box => box === name);
    setFormData(state => ({
      ...state,
      [name]: isNested
        ? state[name].length
          ? []
          : [{ [CONDITION_DATE]: '', [CONDITION_TYPE]: '' }]
        : !state[name],
    }));

    setErrors(state => {
      const erasedState = isNested ? { [name]: [] } : {};
      return {
        ...state,
        [GENERIC]: '',
        ...erasedState,
      };
    });
  }

  function handleIncidentCheckboxChange(e) {
    const { name } = e.target;

    setFormData(state => ({
      ...state,
      [INCIDENTS]: {
        ...state[INCIDENTS],
        [name]: !state[INCIDENTS][name],
      },
    }));

    setErrors(state => {
      return {
        ...state,
        [GENERIC]: '',
      };
    });
  }

  function handleConditionChange({ name, value }, type, idx) {
    setFormData(state => {
      state[type][idx][name] = value;

      return {
        ...state,
      };
    });

    setErrors(state => {
      const key = state?.[type]?.[idx];
      if (key) key[name] = '';

      return { ...state };
    });
  }

  function handleClickAdd(type) {
    setFormData(state => ({
      ...state,
      [type]: [...state[type], { [CONDITION_DATE]: '', [CONDITION_TYPE]: '' }],
    }));
  }

  function handleDelete(type, idx) {
    setFormData(state => {
      state[type].splice(idx, 1);
      return {
        ...state,
      };
    });
  }

  function handleVisitReasonChange(e) {
    setFormData(state => ({
      ...state,
      [VISIT_REASON]: e.target.value,
    }));

    if (errors[VISIT_REASON]) setErrors(state => ({ ...state, [VISIT_REASON]: '' }));
  }

  function renderSubCheckbox(type) {
    return (
      <>
        {formData[type].map((item, idx) => (
          <div key={`${type}-${idx}`} className={styles.newCondition}>
            <div className={styles.conditionInputs}>
              <Select
                isSearchable
                onChange={e =>
                  handleConditionChange(
                    { name: CONDITION_TYPE, value: e.value },
                    type,
                    idx
                  )
                }
                placeholder='Choose a Condition'
                label='Condition'
                options={conditions}
                defaultValue={
                  formData[type][idx][CONDITION_TYPE] && {
                    label: formData[type][idx][CONDITION_TYPE]?.name,
                  }
                }
                id={`${type}-${CONDITION_TYPE}-${idx}`}
                error={{
                  errorMessage: errors[type]?.[idx]?.[CONDITION_TYPE],
                }}
              />

              <TextField
                id={`${type}-${CONDITION_DATE}-${idx}`}
                name={CONDITION_DATE}
                value={formData[type][idx][CONDITION_DATE]}
                placeholder='MM/DD/YYYY'
                onChange={e => handleConditionChange(e.target, type, idx)}
                onKeyUp={handleFormatDateKeyUp}
                error={{
                  errorMessage: errors[type]?.[idx]?.[CONDITION_DATE],
                }}
                label='Condition Start Date'
              />
            </div>

            {formData[type].length > 1 ? (
              <button
                type='button'
                aria-label={`remove condition ${idx + 1}`}
                className={styles.delete}
                onClick={() => handleDelete(type, idx)}
              >
                <Trash />
              </button>
            ) : null}
          </div>
        ))}

        {formData[type].length ? (
          <button
            type='button'
            className={classNames(styles.addCondition, Typography.body2)}
            onClick={() => handleClickAdd(type)}
          >
            <img className={styles.addIcon} src={plusIcon} alt='' />
            Add Another Condition
          </button>
        ) : null}
      </>
    );
  }

  return (
    <ClaimFormAccordion
      canEdit={canEdit}
      isComplete={isComplete}
      isOpen={isOpen}
      title='Conditions & Services'
      onEditClick={onEditClick}
    >
      <form className={styles.root} onSubmit={handleSubmit}>
        <div>
          <Body1 isBold>Reason(s) for vet visit</Body1>

          {incidents.length ? (
            <div className={styles.checkboxes} style={{ marginTop: '16px' }}>
              <Body2 isBold style={{ display: 'flex' }}>
                Previously Identified
                <div className={styles.tooltip}>
                  <Tooltip>
                    Conditions you've selected in a previous claim and those found by
                    our claims team in your pet's records
                  </Tooltip>
                </div>
              </Body2>
              {formatIncidentsToShow(
                incidents,
                formData[INCIDENTS],
                isShowAllIncidents
              ).map(({ sub_diagnosis: { id, name } }) => (
                <div className={styles.checkboxWrapper} key={id}>
                  <Checkbox
                    id={id}
                    name={id}
                    label={name}
                    onChange={handleIncidentCheckboxChange}
                    checked={!!formData[INCIDENTS][id]}
                    classes={{ root: styles.checkbox }}
                  />
                </div>
              ))}

              {incidents.length > 5 ? (
                <button
                  type='button'
                  className={classNames(styles.toggle, ButtonStyles.tertiary)}
                  onClick={toggleShowAllIncidents}
                >
                  {isShowAllIncidents ? 'View Less' : 'View All'}
                  <ChevronRight
                    width={8}
                    height={14}
                    className={isShowAllIncidents ? styles.open : styles.closed}
                  />
                </button>
              ) : null}
            </div>
          ) : null}
        </div>

        <div className={styles.checkboxes}>
          {incidents.length ? <Body2 isBold>New condition or service</Body2> : null}

          {canFileAccidentIllness ? (
            <div className={styles.checkboxWrapper}>
              <Checkbox
                id={ACCIDENT}
                name={ACCIDENT}
                label='New Accident'
                onChange={handleNewConditionCheckboxChange}
                checked={!!formData[ACCIDENT].length}
                classes={{ root: styles.checkbox }}
              />

              {renderSubCheckbox(ACCIDENT)}
            </div>
          ) : null}

          {canFileAccidentIllness ? (
            <div className={styles.checkboxWrapper}>
              <Checkbox
                id={ILLNESS}
                name={ILLNESS}
                label='New Illness'
                onChange={handleNewConditionCheckboxChange}
                checked={!!formData[ILLNESS].length}
                classes={{ root: styles.checkbox }}
              />

              {renderSubCheckbox(ILLNESS)}
            </div>
          ) : null}

          {canFileWellness ? (
            <div className={styles.checkboxWrapper}>
              <Checkbox
                id={WELLNESS}
                name={WELLNESS}
                label='Wellness'
                onChange={handleNewConditionCheckboxChange}
                checked={formData[WELLNESS]}
                classes={{ root: styles.checkbox }}
              />
            </div>
          ) : null}

          {errors[GENERIC] ? (
            <LegalBody className={styles.error}>{errors[GENERIC]}</LegalBody>
          ) : null}
        </div>

        <TextArea
          rows={1}
          id={VISIT_REASON}
          name={VISIT_REASON}
          value={formData[VISIT_REASON]}
          onChange={handleVisitReasonChange}
          error={{
            errorMessage: errors[VISIT_REASON],
          }}
          label={
            canFileAccidentIllness
              ? 'Briefly tell us why you visited the vet.'
              : `Any details about ${pet.name}'s wellness visit you'd like to share?`
          }
          maxLength={400}
          showCount
        />

        <button type='submit' className={ButtonStyles.secondary}>
          Next
        </button>
      </form>
    </ClaimFormAccordion>
  );
}

ClaimConditionsServices.defaultProps = {
  conditions: [],
  incidents: [],
};

ClaimConditionsServices.propTypes = {
  canEdit: PropTypes.bool,
  conditions: PropTypes.array,
  incidents: PropTypes.array,
  isComplete: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onEditClick: PropTypes.func,
  pet: PropTypes.shape({
    policies: PropTypes.array,
    plans: PropTypes.array,
  }),
};

export default ClaimConditionsServices;
