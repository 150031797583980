import { calculateAndFormatISODate, formatISODate } from '@pumpkincare/shared';

import { getLatestRelevantWellness, WELLNESS_STATUS } from './wellness-config-utils';

const DEFAULT_RETURN = { dates: [] };

/*
  This is highly dependent on wellness being reversed sorted at the user-utils level
 */
export function getWellnessDates(wellness, provider = 'Sprout') {
  if (!wellness.length) return DEFAULT_RETURN;

  const latestWellness = wellness[0];
  const latestRelevantWellness = getLatestRelevantWellness(wellness, {
    isSorted: true,
  });

  // pending on enrollment or after renewal
  if (latestWellness.status === WELLNESS_STATUS.PENDING) return DEFAULT_RETURN;

  if (
    [WELLNESS_STATUS.ACTIVE, WELLNESS_STATUS.LAPSED].some(
      status => status === latestRelevantWellness.status
    )
  ) {
    const isDeclined = latestWellness.status === WELLNESS_STATUS.DECLINED;

    const dates = [
      {
        title: `${provider} Start Date`,
        date: formatISODate(latestRelevantWellness.start_date, {
          format: 'MMM D, YYYY',
          inputTimezone: 'local',
        }),
        subtitle: 'Wellness benefits kicked in',
      },
    ];

    if (!isDeclined) {
      dates.push({
        title: `${provider} Plan Renewal Date`,
        date: calculateAndFormatISODate(latestRelevantWellness.start_date, '+1y', {
          format: 'MMM D, YYYY',
          inputTimezone: 'local',
        }),
        subtitle: 'Yearly wellness benefits auto renew',
      });
    } else {
      dates.push({
        title: `${provider} Cancellation Date`,
        date: formatISODate(latestRelevantWellness.end_date, {
          format: 'MMM D, YYYY',
          inputTimezone: 'local',
        }),
        subtitle: 'Wellness benefits end',
      });
    }

    return {
      dates,
    };
  }

  if (
    [WELLNESS_STATUS.CANCELLED, WELLNESS_STATUS.EXPIRED].some(
      status => status === latestRelevantWellness.status
    ) ||
    [WELLNESS_STATUS.VOIDED, WELLNESS_STATUS.CANCEL_PENDING].some(
      status =>
        status === latestWellness.status || status === latestRelevantWellness.status
    )
  ) {
    return {
      dates: [
        {
          title: `${provider} Coverage End Date`,
          date: formatISODate(latestRelevantWellness.end_date, {
            format: 'MMM D, YYYY',
            inputTimezone: 'local',
          }),
          subtitle: 'Wellness benefits end',
        },
      ],
    };
  }

  return DEFAULT_RETURN;
}
