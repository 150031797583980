import { dateIsBefore, formatISODate } from '@pumpkincare/shared';

import { STATUS } from './pet-plans-utils';

export const POLICY_STATUS = STATUS;

/**
 * Do not import directly into code - exported only for testing
 * */
export function getPolicyIsRenewal(policies) {
  if (!policies.length) return {};

  const isRenewal = policies[0].status === POLICY_STATUS.RENEW_PENDING;
  const renewalDate = isRenewal
    ? formatISODate(policies[0].policy_effective_date, {
        format: 'MMM D, YYYY',
        inputTimezone: 'local',
      })
    : null;

  return { isRenewal, renewalDate };
}

/**
 * Do not import directly into code - exported only for testing
 * */
export function getPolicyIsDeclined(policies) {
  if (!policies.length) return {};

  const isDeclined =
    policies[0].status === POLICY_STATUS.DECLINED &&
    !policies.some(policy => policy.status === POLICY_STATUS.CANCELLED);
  const endDate = isDeclined
    ? formatISODate(policies[0].policy_end_date, {
        format: 'MMM D, YYYY',
        inputTimezone: 'local',
      })
    : null;

  return { isDeclined, endDate };
}

/**
 * Do not import directly into code - exported only for testing
 * */
export function getPolicyIsCancelled(policies) {
  if (!policies.length) return {};

  const cancelledPolicy =
    !policies.find(policy => policy.status === POLICY_STATUS.ACTIVE) &&
    policies.find(
      policy =>
        policy.status === POLICY_STATUS.CANCELLED ||
        policy.status === POLICY_STATUS.CANCEL_PENDING
    );

  const isCancelled =
    !!cancelledPolicy ||
    [POLICY_STATUS.EXPIRED, POLICY_STATUS.VOIDED].includes(policies[0].status);
  const isDeceased = cancelledPolicy?.cancellation_reason === 'Deceased';

  return { isCancelled, isDeceased };
}

/**
 * Do not import directly into code - exported only for testing
 * */
export function getPolicyIsWaiting(policies) {
  if (!policies.length) return {};

  const isWaiting =
    policies.length === 1 &&
    policies[0].status === POLICY_STATUS.ACTIVE &&
    dateIsBefore(Date.now(), policies[0].policy_illness_coverage_start_date);
  const startDate = isWaiting
    ? formatISODate(policies[0].policy_illness_coverage_start_date, {
        format: 'MMM D, YYYY',
        inputTimezone: 'local',
      })
    : null;
  const isModelLaw =
    isWaiting &&
    policies[0].policy_effective_date ===
      policies[0].policy_accident_coverage_start_date;

  return { isWaiting, startDate, isModelLaw };
}

/**
 * Do not import directly into code - exported only for testing
 * */
export function getPolicyIsLapsed(policies) {
  if (!policies.length) return {};

  const isLapsed = policies.some(policy => policy.status === POLICY_STATUS.LAPSED);
  return { isLapsed };
}

/**
 * Do not import directly into code - exported only for testing
 * */
export function getPolicyIsActive(policies) {
  if (!policies.length) return {};

  const isActive = policies.some(policy => policy.status === POLICY_STATUS.ACTIVE);
  return { isActive };
}

export function getPolicyConfig(policies) {
  const renewal = getPolicyIsRenewal(policies);
  const declined = getPolicyIsDeclined(policies);
  const cancelled = getPolicyIsCancelled(policies);
  const waiting = getPolicyIsWaiting(policies);
  const lapsed = getPolicyIsLapsed(policies);
  const active = getPolicyIsActive(policies);

  return { active, renewal, declined, cancelled, waiting, lapsed };
}

/*
  returns the latest relevant policy for a pet - there are generally only two recent policies for a pet owner that are relevant:
  the current one and the renewing one
  this returns the current policy (which can include the canceled / expired pets too)
 */
export function getLatestRelevantPolicy(policies) {
  if (
    [
      POLICY_STATUS.ACTIVE,
      POLICY_STATUS.CANCELLED,
      POLICY_STATUS.CANCEL_PENDING,
      POLICY_STATUS.EXPIRED,
      POLICY_STATUS.LAPSED,
      POLICY_STATUS.VOIDED,
    ].some(status => status === policies[0].status)
  )
    return policies[0];

  return (
    (policies[1]?.status === POLICY_STATUS.VOIDED && policies[2]) || policies[1]
  );
}

export function getPolicyPrice(policies, isAnnuallyCharged) {
  if (!policies.length) return {};

  const { premium } = getLatestRelevantPolicy(policies);

  return {
    cost: isAnnuallyCharged ? premium * 12 : premium,
    frequency: isAnnuallyCharged ? 'year' : 'month',
  };
}

export function getPolicyUtilization(policies) {
  if (!policies.length) return [];

  return getLatestRelevantPolicy(policies).utilization;
}

/**
 * Do not import directly into code - exported only for testing
 */
export function formatPolicyDocuments(policyChanges, policyDocuments) {
  // if no document_id, there is no s3 doc
  const policyChangesFiltered = policyChanges
    .filter(policy => policy.document_id !== null)
    .map(change => ({ ...change, isChange: true }));

  const mergedPolicyDocuments = [...policyChangesFiltered, ...policyDocuments];

  const sortedMergedPolicyDocuments = mergedPolicyDocuments.sort(
    (a, b) =>
      new Date(b.processed_at ? b.processed_at : b.created_at) -
      new Date(a.processed_at ? a.processed_at : a.created_at)
  );

  return sortedMergedPolicyDocuments;
}

export function getPolicyDocuments(policies) {
  if (!policies.length) return [];

  const allowedStatuses = [
    POLICY_STATUS.ACTIVE,
    POLICY_STATUS.CANCELLED,
    POLICY_STATUS.CANCEL_PENDING,
    POLICY_STATUS.EXPIRED,
    POLICY_STATUS.VOIDED,
  ];
  const filteredPolicies = policies.filter(policy =>
    allowedStatuses.some(status => status === policy.status)
  );

  return filteredPolicies.reduce((result, policy, idxPolicy) => {
    const sortedDocuments = formatPolicyDocuments(
      policy.policy_changes,
      policy.documents
    );

    sortedDocuments.forEach((document, idxDoc) => {
      /*
       only add documents if there will be an s3 document to return (policy_change already filters for that)
       original policy doc relies on document.location (or policy.policy_s3_path which should be deprecated)
       however, for first document in the list, we should always add it and instead render copy about how doc is still
       being generated
       */
      if (
        document.isChange ||
        document.location ||
        (idxPolicy === 0 && idxDoc === 0)
      ) {
        result.push({
          ...document,
          name: document.isChange
            ? `Updated Insurance Policy ${formatISODate(
                document.processed_at || document.created_at,
                { format: 'MMM D, YYYY' }
              )}`
            : 'Insurance Policy',
          policy_id: policy.id,
          subtitle: `${formatISODate(policy.policy_effective_date, {
            format: 'MMM D, YYYY',
            inputTimezone: 'local',
          })} - ${formatISODate(policy.policy_end_date, { format: 'MMM D, YYYY' })}`,
          processingText:
            !document.isChange && !document.location
              ? "Your latest policy document isn't ready. Hang tight and check back soon."
              : '',
        });
      }
    });

    return result;
  }, []);
}
